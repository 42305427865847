<template>
   <div class="wrapper">
    <header class="header-fixed">
      <div class="header-main">
        <div class="header-logo">
          <div class="header-logo_img">
            <img src="@/assets/img/paw-logo.png" alt="">
          </div>
          <div class="header-logo_title">CAT MONEY</div>
        </div>
      </div>
    </header>

    <section class="tour-suggest list-order">
      <div class="flex justify-between items-center relative">
        <div class="flex items-center back-btn">
            <router-link to="/wallet">

<i class="ti ti-chevron-left text-white back-btn-icon"></i>
</router-link>        </div>
        <div class="title-primary text-white text-title">
          Lịch sử giao dịch
        </div>
      </div>
      <div class="money-history">
        <div class="flex items-center justify-center history-balance-wallet">
          <div class="history-balance">Số dư hiện tại: {{formatMoney(user.balance)}} đ</div>
        </div>
        <div class="history-main" v-for="(history, index) in histories" :key="index">
          <div class="history-items">
            <div class="flex items-center gap-x-3">
              <div class="history-coin_logo">
                <img src="@/assets/img/paw-logo.png" alt="" />
              </div>
              <div class="flex flex-col">
                <p class="history-fax" >
                  Trạng thái: 
                  <!-- <span v-show="history.status == 0" class="p-1 text-white rounded bg-yellow">Đang chờ</span>
                  <span v-show="history.status == 1" class="p-1 text-white rounded bg-green">Đã duyệt</span>
                  <span v-show="history.status == 2" class="p-1 text-white rounded bg-red">Từ chối</span>
                  <span v-show="history.status == 3" class="p-1 text-white rounded bg-blue">Hoa hồng</span> -->
                  <span class=" p-1 text-white rounded bg-yellow" v-if="history.type != 3">
                  {{history.type == 0 ? 'Nạp tiền' : 'Rút Tiền' }}
                  </span>
                  <span class="p-1 text-white rounded bg-green" v-if="history.type == 3">
                    Hoa hồng
                  </span>
                </p>
                <p class="history-time">{{ formatDate(history.created_at) }}</p>
                <!-- <p class="history-type" v-if="history.type != 3">
                  {{history.type == 0 ? 'Nạp tiền' : 'Rút Tiền' }}
                </p>
                <p class="history-type" v-if="history.type == 3">
                  Hoa hồng
                </p>  -->
              </div>
            </div>
            <div class="flex flex-col text-center">
              <p class="history-money_count count-up" v-if="history.status == 0">
               {{ history.type == 1 ? '-222' +  formatMoney(history.amount) : formatMoney(history.amount) }} đ
              </p>
              <p class="history-money_count count-up" v-if="history.status == 1">
                {{ history.type == 1 ? '-222' +  formatMoney(history.amount) : formatMoney(history.amount) }} đ
              </p>
              <p class="history-money_count count-up" v-if="history.status == 2">
               {{ formatMoney(history.amount) }} đ
              </p>
              <p class="history-money_count count-up" v-if="history.status == 3">
               + {{ formatMoney(history.amount) }} đ
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Footer />
  </div>
</template>

<script>
import Footer from '@/views/user/components/Footer'
import network from '@/core/network'
export default {
    components: {Footer},
    data() {
        return {
          histories: [],
          user: {}
        }
    },
    mounted() {
      this.getHistory();
      this.getProfile();
    },
    methods: {
      getProfile() {
        network.get('/users/profile').then( res => {
          console.log(res.data.data);
          this.user = res.data.data;
        }).catch(err => {
          console.log(err);
        })
      },
      getHistory() {
        network.get('/requests/history')
        .then(
          res => {
            this.histories = res.data;
          }
        )
        .catch(err => console.log(err))
      },
      formatDate(timestamp) {
            const date = new Date(timestamp);
            return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
        },
        formatMoney(number) {
       
       return parseInt(number).toLocaleString('vi-VN', {
         useGrouping: true,
         minimumFractionDigits: 0
       }).replace(/\./g, ',');
   }
    }
}
</script>

<style>

</style>